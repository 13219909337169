.Small-User-Card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    text-decoration: none;
    color: inherit;
    column-gap: 1rem;
}

.Small-User-Card:hover {
    background-color: rgba(0,0,0,.08);
}

.Small-User-Card > * {
    display: flex;
    align-items: center;
}

.Small-User-Card-Left {
    min-width: 0;
    flex: 1;
}

.Small-User-Card-Left > .User-Names-Container {
    margin-left: .5rem;
    font-weight: 600;
    min-width: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: 100%;
    container: NamesContainer / size;
}

.User-Names-Container .Displayname {
    white-space: nowrap;
    font-size: 40cqh;
}

.User-Names-Container .Username {
    color: hsl(0,0%,50%);
    font-size: 35cqh;
}

.User-Names-Container .Displayname,
.User-Names-Container .Username
{
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}