.NavLink {
    position: relative;
    display: inline-block;
    width: 100%;
    height: inherit;
    aspect-ratio: 1;

    box-sizing: border-box;
    padding: 15%;
    border: none;

    
    background-color: transparent;
    color: white;
    stroke: white;
    fill: white;
    
    transition: all .1s ease-out;
}

.NavLink.adminBtn {
    border-left: 5px solid yellow;
}

.NavLink-User {
    display: flex;
    background-color: var(--URBAN-GRAY);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%, 80%;
}


/* Icon */
.NL-Icon {
    width: 100%;
    position: relative;
}

.NL-Icon svg {
    width: inherit;
    height: inherit;
}

/* Icon Title */
.NL-IconTitle {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;

    text-align: center;
    color: transparent;
    font-size: smaller;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;

    white-space: nowrap;
}

.NL-IconTitle.visibleTitle {
    color: inherit;
}

@media screen and (hover:hover) {
    .NavLink:hover {
        background-color: rgb(38, 142, 247);
        /* background-color: white;
        fill: black;
        stroke: black;
        color: black; */
    }
    .NavLink:hover .NL-IconTitle:not(.visibleTitle) {
        color: inherit;
    }
    .NavLink-User:hover {
        background-size: 110%, 90%;
    }
}