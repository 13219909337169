._Layout {
    position: fixed;
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 3.5em auto;

    container: Layout / inline-size;
}

._Layout-Content {
    overflow-y: scroll;
    overflow-x: hidden;
    /* background-color: var(--URBAN-SOFT-GRAY); */
}

@media (max-width: 800px) {
    ._Layout {
        grid-template-columns: auto;
        grid-template-rows: 3em auto;
    }
}