#Home {
    display: grid;
    justify-content: center;
    grid-template-columns: min(1300px, 100%);
}

#Home > * {
    margin: .5em 5%;
}

.HomeContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 1em;
    row-gap: 1em;
}

@media screen and (max-width: 700px) {
    #Home main {
        margin: 1em 2%;
    }
    .HomeContent {
        row-gap: 0em;
    }
}

.LoadingContainer {
    height: 2.5em;
    display: flex;
    justify-content: center;
    margin: 1em;
}

.EndFeedMsg {
    text-align: center;
}