#User { 
    min-height: 100%; 
    background-color: var(--URBAN-WHITE);
    /* padding: 2rem 5rem; */
    display: flex;
    justify-content: center;
}

#User .SmallWidth {
    display: none;
}

.User-Container {
    /* background-color: crimson; */
    /* height: 100%; */
    width: min(1200px, 100%);
    display: grid;
    grid-template-columns: 4fr 1.75fr;
    column-gap: 2rem;
    padding: 2rem;
    /* padding-bottom: 0; */
}

.User-Container > main {
    display: flex;
    flex-direction: column;
}

.User-Container > aside {
    max-width: 20rem;
    /* background-color: darkcyan; */
 }

/* SubComponents */

.User-Content {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    /* height: 20em; */
    border: 3px solid rgba(0,0,0,.1); 
    border-top: 0;
    border-radius: 0 0 .5rem .5rem;
}

.User-Content-Container {
    padding: 1em;
    min-height: 20rem;
}

.User-Selections {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    gap: min(1vw, 1rem);
}

.User-Selections > li {
    position: relative;
    font-weight: 600;
    font-size: min(3.5vw, 1rem);
    text-align: center;
}

.User-Selections a {
    display: inline-block;
    flex-grow: 1;
    color: hsl(216.07deg 0% 50%);
    text-decoration: none;
    padding: .5rem min(1vw, 1rem);

    transition: color 250ms;
}

.User-Selections > li.selected a {
    color: var(--URBAN-SKYBLUE);
}

.User-Selections > li:not(.selected):hover a,
.User-Selections > li:not(.selected) a:focus-within {
    color: hsl(216.07deg 70% 55%);
}

.User-Selections > li::after {
    content: "";
    width: 100%;
    height: .2rem;
    position: absolute;
    
    scale: 0 1;
    left: 0;
    bottom: 0;
    border-radius: 1rem;
    background-color:hsl(216.07deg 70% 70%);
    
    transition: 
        scale 250ms 150ms,
        background-color 150ms
        ;
}

.User-Selections > li.selected::after {
    background-color: var(--URBAN-SKYBLUE);
}

.User-Selections > li:hover::after,
.User-Selections > li.selected::after,
.User-Selections > li:focus-within::after
{
    scale: 1 1;
}


@media screen and (max-width: 1000px) {
    .User-Container > main {
        grid-column: 1 / 3;
    }
    .User-Container > aside {
        display: none;
    }

    #User .SmallWidth {
        display: contents;
    }
}

@media screen and (max-width: 700px) {
    .User-Container {
        padding: 0;
    }
    .User-Content {
        border: 0; 
        border-top: 0;
        border-radius: 0;
    }

    .User-Content-Container {
        padding: 1em .5em;
    }

    .User-Container > main > .UserBanner {
        border-radius: 0;
    }
    .User-Container > main .UD-Options.FullScreen {display: none;}
    .User-Container > main .UD-Options.SmallScreen {display: contents;}
}

.UC-Bio-Header {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: stretch; */
    height: 2em;
    padding: 1em 1em 0 1em;
}

.UC-Bio-Header h3 {
    margin: 0;
    margin-right: 1em;
    flex: 1;
    color: var(--URBAN-GRAY);
    border-bottom: 2px solid var(--URBAN-GRAY);
}

.UC-Bio-Body {
    padding: 0 1em;
    margin-bottom: 1.5em;
}