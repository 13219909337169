.FllwTemplate {
    margin-top: 1rem;
    background-color: var(--URBAN-WHITE);
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    border: 1px solid rgba(0,0,0,.1);
}

.FllwTemplate-Header {
    padding: .5rem;
    display: flex;
    justify-content: space-between;
}

.FllwTemplate-Header h2 {
    color: var(--URBAN-GRAY);
    margin: 0;
}

.FllwTemplate-Body {
    border-top: 3px solid rgba(0,0,0,.15);
    padding: .5rem;
}

.FllwTemplate-Body-Empty {
    margin: 0;
    font-style: italic;
    opacity: .5;
}

.FllwTemplate-User {
    height: 2.5rem;
    width: 100%;
    margin: .2rem 0;
    position: relative;
    color: var(--URBAN-GRAY)
}


.FllwTemplate-User-Placeholder {
    background-color: var(--URBAN-LIGHT-GRAY);
}

.FllwTemplate-User-Placeholder.animated {
    animation: sleepingLoadingDiv 4s 0s infinite;
}

.Fllw-Bttn {
    font-weight: 600;
    font-size:medium;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .25rem;

    transition: all .1s ease-out;
}

.Follow-Button {
    background-color: var(--URBAN-SKYBLUE);
    border: 0;
    color: var(--URBAN-WHITE);
}
.Follow-Button:disabled {background-color: var(--URBAN-DISABLED-BLUE);}

.Unfllw-Button {
    background-color: transparent;
    color: var(--URBAN-GRAY);
    border: 2px solid var(--URBAN-GRAY);
}
.Unfllw-Button:disabled {border-color: rgba(0,0,0,.3); color: rgba(0,0,0,.3);}

.Follow-Button:focus-visible, 
.Follow-Button:active 
{background-color: var(--URBAN-FOCUS-BLUE);}
.Unfllw-Button:focus-visible, 
.Unfllw-Button:active 
{border-color: rgba(0,0,0,.5); color: rgba(0,0,0,.5);}

@media screen and (hover: hover) {
    .Fllw-Bttn:hover {cursor: pointer;}
    .Follow-Button:hover {background-color: var(--URBAN-FOCUS-BLUE);}
    .Unfllw-Button:hover {border-color: rgba(0,0,0,.5); color: rgba(0,0,0,.5);}
}

.FllwTemplate-More {
    display: flex;
    justify-content: center;
    padding: .25rem 1.25rem .25rem .25rem;
    margin: 0 .5rem .5rem .5rem;
    align-items: center;
    text-decoration: none;
}


@media screen and (hover:hover) {
    .FllwTemplate-More:hover {
        background-color: hsl(0,0%,90%);
    }
}
/* .FllwTemplate-More > * {
    flex: 1;
} */

.FllwTemplate-More .Stack {
    display: grid;
    max-width: 8rem;
    grid-template-columns: repeat(auto-fit, minmax(.1rem, 1.5rem));
    position: relative;
    z-index: 0;
    margin-right: 2rem;
    justify-content: right;
}

.FllwTemplate-More .Stack .Plus {
    width: 2rem;
    height: 1.5rem;
    padding: .2rem;
    color: white;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    right: -.3rem;
}

.FllwTemplate-More .Stack .Plus circle {
    fill: var(--URBAN-GRAY);
}


.FllwTemplate-More .Stack > *:not(.Plus) {
    outline: white solid .2rem;
}

.FllwTemplate-More .More {
    font-weight: 500;
    color: var(--URBAN-GRAY);
    position: relative;
    margin-left: -1.5rem;
}