#Login {
    grid-template-columns: 1fr;
}

#Login .TitleSection {
    display: flex;
    column-gap: 1em;
    justify-content: center;
    align-items: center;

    margin: 1em 10%;
}

#Login .TitleSection .LogoContainer {
    position: relative;
    background-color: var(--URBAN-BLUE);
    display: inline-block;
    height: clamp(1rem, 50vw, 5rem);
    width: clamp(1rem, 50vw, 5rem);
    padding: clamp(1rem, 10vw, 2rem);
    border-radius: 50%;
}

#Login .TitleSection .LogoContainer svg {
    fill: white;
    stroke: white;

    height: inherit;
    width: inherit;
}

#Login .TitleSection .Title {
    font-weight: 700;
    color: var(--URBAN-BLUE);
}

#Login .TitleSection .Title span:first-child {
    font-size: 2.5em;
}

#Login .TitleSection .Title span:nth-child(3) {
    position: relative;
    top: -.5em;
    font-size: 1.25em;
}

