.spinner {
    display: inline-block;
    background-color: inherit;
    box-sizing: border-box;
    border: .2rem solid;
    border-radius: 50%;
    border-color: 
        currentColor
        hsl(0,0%,100%) 
        hsl(0,0%,100%) 
        hsl(0,0%,100%);
    width: 100%;
    height: 100%;
    aspect-ratio: 1;

    animation: spinner 3s linear infinite;
}

.delay-vis-spinner {
    visibility: hidden;
    animation: 
        spinner 3s linear infinite,
        delay-vis 0s 250ms forwards
        ;
}

@keyframes delay-vis {
    to {
        visibility: visible;
    }
}

@keyframes spinner {
    80% {
        transform: rotate(2.75turn);
    }
    100% {
        transform: rotate(4turn);
    }
}

@keyframes constant-spinner {
    100% {
        transform: rotate(3turn);
    }
}

/* svg circle#border {
    animation:
        dashoffset-anim 1s linear infinite,
        dasharray-anim 2s linear infinite alternate-reverse,
        close-ouroboros-anim 250ms linear forwards
        ;
    animation-play-state: paused, paused, running;
} */
    
svg.svg-ouroboros-spinner circle#border {
    animation:
        dashoffset-anim 1s linear infinite,
        dasharray-anim 3s ease-in-out infinite alternate-reverse
        ;
}
    
@media (prefers-reduced-motion) {
    svg.svg-ouroboros-spinner circle#border {
        stroke-dasharray: 25 75;
        animation:
            dashoffset-anim 2s linear infinite
            ;
    }
}

/* @keyframes close-ouroboros-anim {
    to {
        stroke-dasharray: 100 0;
        stroke-dashoffset: 0;
    }
} */

@keyframes dashoffset-anim {
    from {
        stroke-dashoffset: 100;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dasharray-anim {
    0% {
        stroke-dasharray: 10 90;
    }
    100% {
        stroke-dasharray: 75 25;
    }
}