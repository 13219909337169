.Doc-Card-Link-Wrapper {
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.Doc-Card {
    border-radius: 0.25em;
    width: 100%;
    min-width: 10em;
    /* height: 100%; */
    box-sizing: border-box;
    position: relative;
    padding-bottom: min(2%, 20px);
}

.Doc-Card >  *:not(.DC-Banner) {
    margin: 0;
}

.Doc-Card, .DC-Light{
    --DC-BGC: var(--URBAN-WHITE);
    --DC-C: var(--URBAN-BLACK);
    --DC-CBGC: var(--URBAN-SOFT-GRAY);
}

.DC-Dark {
    --DC-BGC: var(--URBAN-GRAY);
    --DC-C: var(--URBAN-SOFT-WHITE);
}

.Doc-Card {
    background-color: var(--DC-BGC);
    color: var(--DC-C);

    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
}

.DC-Author {
    display: flex;
    align-items: center;
    margin-top: .8em;
}

.DC-Author > .Authorship-Link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.DC-Author > .DC-Author-Info {
    margin: 0;
    padding-left: max(1%, 10px);
    height: var(--USERPFP-SM-SZ);
    color: rgba(75,85,85,1);
    font-weight: 600;
}

.DC-Date {
    font-weight: 400;
}

.DC-Banner {
    width: 100%;
    /* height: 8em; */
    border-radius: .25em;
    aspect-ratio: 2;
    background-color: lightgray;
    background-size: 100%;
    background-position: center;
    transition: background-size .15s ease-out;
}

@media (prefers-reduced-motion) {
    .DC-Banner {
        transition: none;
    }
}

.DC-Content {
    position: relative;
    padding: .8em .4em;
}

.DC-Title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: clamp(1rem, 2vw, 1.15rem);
}

.DC-Subtitle {
    font-weight: 400;
    margin: .25rem 0 1em 0;
    font-size: 1rem;
}

.DC-Text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Doc-Card:hover {
    cursor: pointer;
    /* outline: 3px solid rgba(0,0,0,.05); */
}

.Doc-Card:hover .DC-Banner {
    background-size: 102.5%;
}

@media (max-width: 800px) {
    .Doc-Card {
        width: 100%;
    }
}

.Empty.Doc-Card {
    transition: none;
}

.Empty.Doc-Card:hover {
    cursor: default;
    transform: none;
}

.Empty.DC-Author {
    background-color: darkgray;
}
.Empty.DC-Title,
.Empty.DC-Subtitle,
.Empty.DC-Date {
    background-color: lightgray;
}

.Empty.DC-Author {
    display: block;
    height: var(--USERPFP-SM-SZ);
}
.Empty.DC-Title { height: 1.5rem; }
.Empty.DC-Subtitle { 
    height: 1rem; 
    width: 75%;
}
.Empty.DC-Date {
    height: .75rem;
    width: 25%;
}