.AccountAccess {
    display: grid;
    width: 100%;
    min-height: 100%;
    /* justify-content: center; */
    align-items: center;
    justify-items: center;
}

.AA-Container {
    min-width: 0;
    width: min(100%, 600px);
}

.AA-Header > *, .AA-Content {
    margin-left: 10%;
    margin-right: 10%;
}

.AA-Header {
    color: var(--URBAN-BLUE);
}

.AA-Header > *:first-child {
    margin-bottom: 0;
    font-size: clamp(1.5em, 5vw, 2.5em);
    font-family: Arial, Helvetica, sans-serif;
}

/* .AA-Content {
    padding-top: 2em;
} */

.AA-Form label {
    color: var(--URBAN-GRAY);
    font-weight: 600;
}

.AA-Form input[type=text],
.AA-Form input[type=email],
.AA-Form input[type=password] {
    margin: .25rem 0 .5rem 0;
    border: none;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    
    transition: background-color .25s;
    transition: outline-color .25s;
    outline: 2px solid transparent;
    width: 100%;
    height: 2rem;
}

.AA-Form input[type="text"],
.AA-Form input[type="email"],
.AA-Form input[type="password"]
{
    background-color: var(--URBAN-CLOUD);
    color: var(--URBAN-GLOOM);
}

.AA-Form input:valid:not([type=email]):not(:focus) {
    background-color: white;
    outline-color: #AAA;
}

.AA-Form input.invalid:valid:not(:focus),
.AA-Form input[type=password].invalid {
    background-color: white;
    outline-color: hsl(0,75%,70%) !important;
}

.AA-Form input[type=text]:focus, 
.AA-Form input[type=email]:focus, 
.AA-Form input[type=password]:focus {
    outline-color: lightskyblue;
    background-color: white;
}

.AA-Btn-Wrapper {
    width: 100%;
    text-align: center;
    margin: 2.5em 0 1em 0;
}

.AA-Form button {
    color: var(--URBAN-WHITE);
    background-color: var(--URBAN-BLUE);
    width: 100%;
    height: 2.5em;
    font-size: large;
    border: none;
    border-radius: .25rem;
}

.AA-Form button:hover, 
.AA-Form button:focus-visible {
    cursor: pointer;
    background-color: var(--URBAN-SKYBLUE);
    outline: var(--URBAN-LBLUE);
}
.AA-Form button:disabled:hover {
    background-color: hsl(0,0%,50%);
    cursor: default;
}

.AA-Form button:disabled:has(span):hover {
    background-color: var(--URBAN-BLUE);
    cursor: default;
}

.AA-Form button span {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: .2rem solid;
    border-color: 
        var(--URBAN-BLUE)
        hsl(0,0%,100%)
        hsl(0,0%,100%)
        hsl(0,0%,100%);
    line-height: 1;
    animation: spinner 3s linear 0s infinite;
    vertical-align: middle;
}

p a:visited {
    color: LinkText;
}

.AA-Content .P-Error {
    font-weight: 500;
    color: hsl(0,75%,60%);
    position: relative;
    font-size: clamp(.75em, 4vw, 1em);
}

.AA-Content .P-Error b,
.AA-Content .P-Error em
{
    font-weight: 700;
}

.AA-Content .P-Error::before {
    content: "!";
    display: inline-block;
    /* color: white;
    background-color: hsl(0, 75%, 60%); */
    color: hsl(0, 75%, 60%);
    line-height: 1; /* yes! */
    text-align: center;
    height: 1em;
    aspect-ratio: 1;
    border-radius: 50%;
    
    font-weight: 700;
    border: .15rem solid;
}

.AA-Content .P-Error.empty {
    visibility: hidden;
}

.AA-Content .P-Error.inline {
    display: inline;
    font-size: .8rem;
}

.AA-Content .P-Error.inline::before {
    scale: .9;
    margin-left: .25rem;
}