#Signup {
    /* grid-template-columns: 50%; */
}

#Signup .signup-LimitInput {
    margin: .25rem 0 .5rem 0;
}

#Signup .signup-LimitInput input{
    margin-left: 0 !important;
}

#Signup .signup-LimitInput input:valid:not(:focus),
#Signup .signup-LimitInput input:disabled
{
    background-color: white;
    outline-color: #AAA;
}

#Signup .signup-LimitInput.limit-input-Invalid input:valid:not(:focus) {
    background-color: white;
    outline-color: hsl(0,75%,70%);
}


#Signup .AA-Header > *, #Signup .AA-Content {
    margin-left: 5%;
    margin-right: 5%;
}


#Signup .AA-Container {
    min-height: 500px;
}

#Signup .AA-Btn-Wrapper {
    width: 100%;
    margin: 0;
}

#Signup .AA-Form {grid-area: form}
#Signup .AA-C-Discl {grid-area: discl}
#Signup .AA-C-Errs {grid-area: errors}

#Signup .AA-Content {
    margin-bottom: 2em;
}

#Signup .AA-C-Discl {
    background-color: var(--URBAN-CODE-GRAY);
    color: var(--URBAN-WHITE);
    padding: .7em;
    margin: 1.2em;
    border-radius: 5px;
}

#Signup .AA-C-Discl > h3 {
    margin-top: 0;
}


@media (max-width: 1000px){
    #Signup .AA-Content {
        grid-template-areas: 
            'form'
            'errors'
            'discl'
        ;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 800px) {
    #Signup .AA-Container {
        min-height: 100%;
    }
}

#Signup #signupcode {
    letter-spacing: .25rem;
    max-width: calc(7 * .8em);
}

#Signup .username-checker-indicator {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    aspect-ratio: 1;
    height: 1rem;
}

#Signup .username-checker-indicator svg {
    box-sizing: border-box;
    height: 1rem;
    width: 1rem;
    border: .13rem solid currentColor; /*hsl(0, 0%, 75%);*/
    border-radius: 50%;
}

#Signup .username-checker-indicator:not(.spinning, .invalid, .valid) svg {    
    border-color: hsl(0, 0%, 75%) hsl(0, 0%, 75%) hsl(0, 0%, 75%) hsl(0, 0%, 75%);
}

#Signup .username-checker-indicator.spinning svg {
    border-color: hsl(0, 0%, 50%) hsl(0, 0%, 75%) hsl(0, 0%, 75%) hsl(0, 0%, 75%);
    animation: spinner 2s linear 0s infinite normal;
}
#Signup .username-checker-indicator:is(.spinning, :not(.spinning, .invalid, .valid)) svg * {
    display: none;
}

#Signup .username-checker-indicator.spinning.waiting svg {
    animation: constant-spinner 7s linear 0s infinite normal;
}

#Signup .Availability-Checker.Availability-Checker {
    color: #000B;
}

#Signup .Availability-Checker:has(.username-checker-indicator.invalid) {
    border-color: hsl(0,75%,60%);
    color: hsl(0,75%,60%);
}

#Signup .Availability-Checker:has(.username-checker-indicator.valid) {
    border-color: var(--URBAN-FOCUS-BLUE);
    color: var(--URBAN-FOCUS-BLUE);
    /* border-color: hsl(147, 50%, 50%);
    color: hsl(147, 50%, 50%); */
}

#Signup .Availability-Checker {
    display: flex;
    align-items: center; 
    margin-top: .15rem;
    margin-bottom: .15rem;
}

#Signup .Availability-Checker,
#Signup .requirements {
    font-size: .8rem;
    font-weight: 500; 
    color: #000B;
}

#Signup .requirements {
    margin-bottom: .5rem;
}

#Signup .requirements .invalid {
    color: hsl(0,75%,60%);
}

/* 
@media screen and (max-width: 300px) {
    #Signup label:has(+ p:not(.empty))::after {
        content: "\A";
        white-space: pre;
    }
} */

#Signup .AA-Form input:not([type="email"]):disabled
{
    background-color: white;
    outline-color: #AAA;
}