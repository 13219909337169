:root{
  --URBAN-DARK-BLUE: rgb(38, 63, 105);
  --URBAN-BLUE: #315188;
  --URBAN-LBLUE: #076A95;
  --URBAN-SKYBLUE: #2364C6;
  --URBAN-CLOUD: #dcdcdc;
  --URBAN-GLOOM: #333;
  /* The ones below are temporary */
  --URBAN-SOFT-GRAY: #EEE;
  --URBAN-LIGHT-GRAY: #999;
  --URBAN-GRAY: rgb(50,50,50);
  --URBAN-CODE-GRAY: #444;
  --URBAN-BLACK: #252525;
  --URBAN-WHITE: #fff;
  --URBAN-SOFT-WHITE: #eee;

  --URBAN-RED: rgb(242, 33, 33);

  --URBAN-PURPLE: rgb(152, 88, 152);
  --URBAN-ACTIVE-PURPLE: rgb(73, 41, 73);

  --URBAN-HOVER-RED: rgb(197, 29, 29);
  --URBAN-HOVER-WHITE: #bbb;
  --URBAN-HOVER-BLUE: rgb(119, 198, 248);
  --URBAN-FOCUS-BLUE: rgb(58, 161, 226);
  --URBAN-DISABLED-BLUE: rgb(163, 212, 242);
  --URBAN-DISABLED-GRAY: #dcdcdc44;
  --URBAN-DISABLED-FOCUS-BLUE: rgb(145, 207, 245);

  --URBAN-ERROR-RED-BGC: rgb(227, 156, 170);
  --URBAN-ERROR-RED-C: rgb(221, 23, 62);
  --URBAN-ERROR-RED-CBGC: rgb(236, 219, 223);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}