:root {
    --USERPFP-SM-SZ: 2.5em;
}

.userpfp {
    display: inline-block;
    background: gray;
    background-size: cover, 80%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.userpfp.sharpEdge { border-radius: 0;  }
.userpfp.softEdge  { border-radius: 10%; }

.userpfp-XS {
    width: 1.5em;
    height: 1.5em;
}

.userpfp-S {
    width: 2em;
    height: 2em;
}

.userpfp-SM {
    width: 2.5em;
    height: 2.5em;
}

.userpfp-M {
    width: 3.5em;
    height: 3.5em;
}

.userpfp-L {
    width: 5em;
    height: 5em;
}

.userpfp-XL {
    width: 7em;
    height: 7em;
}

/* --- Edit Button --- */

.UPFP-Edit-btn {
    position: absolute;
    width: 20%;
    height: 20%;
    right: 0;
    background-color: transparent;
    font-weight: 700;
    display: none;
    transition: all .05s ease-in-out;
}
.userpfp:hover > .UPFP-Edit-btn {
    display: block;
}
.UPFP-Edit-btn:hover{
    background-color: var(--URBAN-BLUE);
    color: var(--URBAN-WHITE);
    border-color: var(--URBAN-WHITE);
}