:root {
    --skeleton-color: hsl(0, 0%, 45%);
}

.Skeleton-Div {
    width: inherit;
    height: inherit;

    background-color: var(--skeleton-color);
    border-radius: .25rem;
}

.Skeleton-Div * {
    visibility: hidden;
}

.Skeleton-Line {
    height: 1rem;
    background-color: var(--skeleton-color);
    margin: .15rem;
}