.limit-input {
    margin: 1em 0;
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.limit-input > input {
    flex: 1;
    border: 0;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0;
    padding-left: 5px;

    color: var(--URBAN-GLOOM);
    font-weight: 600;
    background-color: var(--URBAN-CLOUD);
    transition: background-color .25s;
    transition: outline-color .25s;
    outline-color: transparent;
    outline-style: solid;
    outline-width: 2px;
    height: 2rem;
}

.limit-input > input:focus {
    outline-color: lightskyblue;
    background-color: white;
}

.limit-input > input:focus ~ span {
    color: var(--URBAN-FOCUS-BLUE);
}

.limit-input > span {
    margin: 5px;
    font-weight: 700;
    color: #777;
    min-width: 40px;
    text-align: center;
    box-sizing: border-box;
    transition: color .25s;
}

.limit-input-Invalid > input:focus {
    outline-color: hsl(0,75%,70%);
}

.limit-input-Invalid > span,
.limit-input-Invalid > input:focus ~ span
{
    color: hsl(0,75%,60%);
}

.limit-input-Invalid > input:not(:focus) {
    background-color: hsl(0,75%,85%);
    color: hsl(0,0%,20%);
}