#Collection header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--URBAN-GRAY);
    margin-bottom: 1em;
    padding: .25em 10px;
    height: 3.5em;
}

#Collection header h1 {
    color: var(--URBAN-WHITE);
    margin: 0;
}

.Collection-Drafts {
    display:  grid;
    justify-self: center;
    row-gap: 1em;
}

@container Layout (min-width: 0px) {
    .Collection-Drafts {
        width: clamp(0px, 90cqw, 1000px);
    }
}

.NewDraftCard {
    height: 2.5rem;
    text-align: left;
    
    --color: hsl(0,0%,25%);

    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 1.5rem;

    color: var(--color);

    font-weight: 500;
    display: flex;
    align-items: center;
}

.NewDraftCard:disabled:hover {
    color: var(--color);
    cursor: default;
}

.NewDraftCard .PlusCircleContainer {
    display: inline-block;
    box-sizing: border-box;
    padding: .5rem;
    height: clamp(2.5rem, 50%, 5rem);
    aspect-ratio: 1;
    background-color: currentColor;
    border-radius: .2rem;
    margin-right: .5rem;

    width: auto;
    position: relative;
}

.NewDraftCard svg circle.border {
    border-width: .20rem;
}

.NewDraftCard div > svg {
    width: 100%;
    height: 100%;
    color: white;
    stroke: white;
}

#Collection .retry-button {
    position: relative;

    width: 4rem;
    height: 4rem;
    padding: .5rem;

    background-color: transparent;
    border: none;
    color: var(--URBAN-GRAY);
    border-radius: .5rem;
}


@media screen and (hover:hover) {
    .NewDraftCard:hover {
        cursor: pointer;
        color: var(--color);
        outline: .2rem solid var(--color);
        border-radius: .25rem;
    }

    #Collection .retry-button:hover {
        cursor: pointer;
        color: hsl(0,0%,60%);
    }
}

@media (max-width: 800px) {
    #Collection header {
        height: auto;
        /* flex-direction: column; */
        /* justify-content: stretch; */
    }
}