#p404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: hsl(0,0%,60%);
}

#p404 h1 {
    margin: 0;
    font-size: clamp(7.5rem, 35vw, 10em);
    letter-spacing: -1rem;
}

#p404 h1 span {
    text-shadow: 
        -.25rem -.25rem white,
        -.25rem 0 white,
        -.25rem .25rem white
        ;
}

#p404 p {
    font-weight: 500;
    font-size: 1.25rem;

    text-align: center;
    text-wrap: balance;
}

#p404 p b {
    color: hsl(0,0%,50%);
}