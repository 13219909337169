.SmallUserCard-Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 1.5em;
    row-gap: .5em;
    color: var(--URBAN-GRAY);
}

.SmallCard-Container {
    height: min(7.5vw, 2.5em);
}

.FollowDate {
    color: hsl(0,0%,50%);
    white-space: nowrap;
}