.User-Follows-Toggler {
    padding: .15rem;
    background-color: hsl(0,0%,85%);
    border-radius: .25rem;
    margin-bottom: 1rem;
}

.User-Follows-Toggler a {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    position: relative;
    padding: .25rem;
    background-color: hsl(0,0%,90%);
    border-radius: .25rem;

    text-decoration: none;
}

.User-Follows-Toggler a:after {
    content: "";
    position: absolute;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    width: 50%;
    background-color: var(--URBAN-SKYBLUE);
    top: 0;
    transition: left 150ms ease-out;
}

.User-Follows-Toggler.Left a:after {
    left: 0;
    border-radius: .2rem 0 0 .2rem;
}

.User-Follows-Toggler.Right a:after {
    left: 50%;
    border-radius: 0 .2rem .2rem 0;
}

.User-Follows-Toggler a span {
    z-index: 2;
    color: white;
    font-size: large;
    font-weight: 600;
    transition: color 150ms ease;
}

.User-Follows-Toggler.Right .User-Followers-Span {
    color: hsl(0,0%,50%);
}

.User-Follows-Toggler.Left .User-Following-Span {
    color: hsl(0,0%,25%);
}