.DraftCard {
    --bannerSrc: "";

    background-color: var(--URBAN-WHITE);
    /* height: var(--DC-Height);
    margin: var(--DC-Margin); */
    box-shadow: 0rem 0rem 0rem .1rem rgba(0,0,0,.1);
    border-radius: .25rem;
    position: relative;

    display: flex;
    flex-direction: row;
    min-width: 0;
    /* overflow: hidden; */
}


/* Banner */

.DraftCard .Banner {
    background-color: darkgray;
    display: inline-block;
    height: 100%;
    width: inherit;
    aspect-ratio: 2;

    border-radius: .25rem;

    background-image: var(--bannerSrc);
    background-position: center;
    background-size: contain;
}

.DraftCard a {
    flex: 1;

    text-decoration: none;
    color: inherit;
    min-width: 0;
    display: flex;
}

/* Main Section Items */

.DraftCard .Main {
    flex: 1;
    min-width: 0;
    
    margin: .5rem;
    padding: .25rem;
    align-content: center;
}

.DraftCard .Main div {
    min-width: 0;
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.DraftCard .Main .Title {
    font-weight: 700;
}
.DraftCard .Main .SubTitle {
    font-weight: 600;
}

.DraftCard .Main .Info {
    padding-top: .5rem;
    font-weight: 400;
}

/* Options */

.DraftCard .Options {
    width: 2rem;
    display: flex;
    align-items: center;
    background-color: var(--URBAN-GRAY);
    backdrop-filter: blur(5px) brightness(80%);
}

.DraftCard .Options button {
    /* border-radius: 50%; */
    height: 100%;
    width: 100%;
    /* aspect-ratio: 1; */

    background-color: transparent;
    border: none;
    /* rotate: 90deg; */
}

.DraftCard .Options button:focus-visible {
    outline: 3px solid var(--URBAN-FOCUS-BLUE);
}

.DraftCard .Options button svg {
    width: 100%;
    height: min(100%, 2rem);
    fill: white;
    rotate: 90deg;
}

.DraftCard .Options button:active {
    background-color: hsl(0,0%,85%);
}

.DraftCard .Menu {
    flex: 0 0;
    height: 100%;
    max-width: 7rem;
    min-width: 0;
    transition: flex 250ms 0s, border-left-width 100ms 0s;
    display: flex;
    align-items: center;
    overflow: hidden;

    container: DraftCardMenu / normal;
    background-color: var(--URBAN-GRAY);
    border-color: gray;
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;

    backdrop-filter: blur(5px) brightness(80%);
}

.DraftCard .Menu svg {
    width: 2rem;
}

.DraftCard .Menu.open {
    flex: 1;
    border-left-width: 2px;
}

.DraftCard .Menu button {
    width: 10rem;
    display: flex;
    align-items: center;
    margin: .25rem;

    font-size: 1rem;

    background-color: transparent;
    border: none;
    border-radius: .25rem;

    color: white;
}

@container DraftCardMenu (max-width: 400px) {
    .DraftCard .Menu button span {
        visibility: hidden;
    }
}

@media screen and (max-width: 500px) {
    .DraftCard .Banner {
        display: none;
    }

    .DraftCard {
        background-image: var(--bannerSrc);
        background-size: cover;
        background-position: center;
    }
        
    .DraftCard:has(.Banner) .Main {
        color: white;
        backdrop-filter: blur(5px) brightness(80%);
        text-shadow: 0px 0px 2px black;
        background-color: hsla(0,0%,0%, .2);
        border-radius: .25rem;
    }
    
    .DraftCard:has(.Banner) .Options, 
    .DraftCard:has(.Banner) .Menu
    {
        background-color: hsla(0,0%,0%, .2);
        border: 0;
    }

    .DraftCard:has(.Banner) .Menu.open {
        margin-left: 2px;
    }
}

@media screen and (hover:hover) {
    .DraftCard:hover {
        outline: .2rem solid dodgerblue;
    }
    
    .DraftCard a:hover,
    .DraftCard .Options:hover,
    .DraftCard .Menu button:hover 
    {
        cursor: pointer;
    }

    .DraftCard .Options button:hover {
        background-color: hsl(0,0%,60%);
        cursor: pointer;
    }

    .DraftCard .Menu button:hover {
        background-color: hsla(0,0%,100%,.2);
    }

}
@media screen and (hover:hover) and (max-width: 500px) {
    .DraftCard .Options button:hover {
        background-color: hsla(0,0%,100%,.4);
        cursor: pointer;
    }
}
/* Menu */